
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import {active} from "@/core/data/genericData";
export default defineComponent({
  name: "add-product-modal",
  components: {
    
    },

  setup() {

    const active_list_data = active;
    const formRef = ref<null | HTMLFormElement>(null);
    const addProductStandardModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter(); 
    const standard = ref([]);
    onMounted( async () => {
      //const db_data = {}
      await setStandardata(standard.value)
    });
    
    const setStandardata = async (data) => {
      const db_data = {}
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_GET_PRODUCT_STANDARD_MASTER_LIST, db_data).then(({ data }) => {
          standard.value = data.standard_list;
          //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const formData = ref({
      num: "",      
      standard_select: "",
      comm: "",
      lic: "",
      title: "",
    });    

    const rules = ref({
      num: [
        {
          required: true,
          message: "Standard Number is required",
          trigger: "change",  
             
        },  
      ],   
       standard_select: [
        {
          required: true,
          message: "Standard Type is required",
          trigger: "change",
              
        }, 
       ],
       lic : [
         {
          min : 0
        }, 
       ],
    });

    const setProductStandardData = async (data) => {

      const db_data = {
        "standard_no": data.num,
        "standard_master_id": data.standard_select,
        "comm_no": data.comm,    
        "no_of_lic": data.lic,    
        "standard_title": data.title,
        "created_user_id": "1",
        "created_ip": "0.0.0.0",
        "created_uagent": "Test",
        "active" : data.active,
        }

          await store.dispatch(Actions.CUST_ADD_PRODUCT_STANDARD, db_data).then(({ data }) => {
          if (data.standard_id){
        
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "Product Standard has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addProductStandardModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });
    }

     const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setProductStandardData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });    

      // formRef.value.validate((valid) => {
      //   if (valid) {
      //     loading.value = true;

      //     setTimeout(() => {
      //       loading.value = false;

      //       Swal.fire({
      //         text: "Company has been successfully created.",
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn btn-primary",
      //         },
      //       }).then(() => {
      //         hideModal(addCompanyModalRef.value);              
      //         router.go(0)
      //       });
      //     }, 2000);
      //   } else {
      //     Swal.fire({
      //       text: "Sorry, looks like there are some errors detected, please try again.",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });
      //     return false;
      //   }
      // });
    };

    return {      
      formData,      
      rules,
      submit,
      formRef,
      loading,
      addProductStandardModalRef,
      setStandardata,
      standard,
      active_list_data,
    };
  },
});
